import React, { useState } from "react";

export default function Signature() {
  const [signature, setSignature] = useState("");

  return (
    <>
      <fieldset>
        <legend>Provider Signature</legend>
        <div className="row">
          <div className="column">
            <div style={{ display: "flex" }}>
              <label
                style={{ width: 190, display: "inline-block" }}
                htmlFor="providerSignature"
              >
                Signer's Name
              </label>
              <input
                required
                type="text"
                id="providerSignature"
                name="providerSignature"
                onChange={(e) => setSignature(e.target.value)}
              />
            </div>
            <div style={{ display: "flex" }}>
              <label style={{ width: 190 }} htmlFor="date">
                Date:
              </label>
              <input required type="date" id="date" name="date" />
            </div>
          </div>
          <div className="column">
            <div
              style={{
                border: "1px solid black",
                borderRadius: 5,
                height: 91,
                fontFamily: "Allison, cursive",
                fontSize: "4rem",
                textAlign: "center",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              {signature}
            </div>
          </div>
        </div>
      </fieldset>
      <div className="row">
        <div className="column" style={{ textAlign: "center" }}>
          <button type="submit">Adopt Signature & Submit</button>
        </div>
      </div>
    </>
  );
}
