import React from "react";
import Signature from "../shared/signature";

const InsuranceVerificationForm = () => {
  const handleSubmit = (e) => {
    e.preventDefault();
    const formData = new FormData(e.target);
    const data = {};
    for (let [key, value] of formData.entries()) {
      if (key === "serviceType") {
        data[key] = data[key] ? [...data[key], value] : [value];
      } else if (key === "email[]") {
        data[key] = data[key] ? [...data[key], value] : [value];
      } else {
        data[key] = value;
      }
    }
    console.log(data);
  };

  return (
    <div className="container">
      <br />
      <h3>Membrane Wrap Insurance Verification</h3>
      <form onSubmit={handleSubmit}>
        <fieldset>
          <div className="row">
            <div className="column">
              <label htmlFor="distributorName">Distributor Name:</label>
              <input type="text" id="distributorName" name="distributorName" />
            </div>
            <div className="column">
              <label htmlFor="distributorEmail">Distributor Email:</label>
              <input
                type="email"
                id="distributorEmail"
                name="distributorEmail"
              />
            </div>
          </div>
        </fieldset>

        <fieldset>
          <legend>Patient</legend>

          <div className="row">
            <div className="column">
              <label htmlFor="lastName">Last Name:</label>
              <input required type="text" id="lastName" name="lastName" />
            </div>
            <div className="column">
              <label htmlFor="firstName">First Name:</label>
              <input required type="text" id="firstName" name="firstName" />
            </div>
          </div>

          <div className="row">
            <div className="column">
              <label htmlFor="dob">Date of Birth:</label>
              <input required type="date" id="dob" name="dob" />
            </div>
            <div className="column">
              <label htmlFor="address">Address:</label>
              <input type="text" id="address" name="address" />
            </div>
          </div>

          <div className="row">
            <div className="column">
              <label htmlFor="city">City:</label>
              <input type="text" id="city" name="city" />
            </div>
            <div className="column">
              <label htmlFor="state">State:</label>
              <input type="text" id="state" name="state" />
            </div>
            <div className="column">
              <label htmlFor="zip">ZIP Code:</label>
              <input type="text" id="zip" name="zip" />
            </div>
          </div>
        </fieldset>
        <fieldset>
          <legend>Insurance</legend>
          <div className="row">
            <div className="column">
              <label htmlFor="primaryInsurance">Primary Insurance:</label>
              <input
                type="text"
                id="primaryInsurance"
                name="primaryInsurance"
                required
              />
            </div>
            <div className="column">
              <label htmlFor="primaryPolicy">Primary Policy #:</label>
              <input
                required
                type="text"
                id="primaryPolicy"
                name="primaryPolicy"
              />
            </div>
          </div>
          <div className="row">
            <div className="column">
              <label htmlFor="secondaryInsurance">Secondary Insurance:</label>
              <input
                type="text"
                id="secondaryInsurance"
                name="secondaryInsurance"
              />
            </div>
            <div className="column">
              <label htmlFor="secondaryPolicy">Secondary Policy #:</label>
              <input type="text" id="secondaryPolicy" name="secondaryPolicy" />
            </div>
          </div>
        </fieldset>
        <fieldset>
          <legend>Requesting Provider</legend>
          <div className="row">
            <div className="column">
              <label htmlFor="providerName">Provider Name:</label>
              <input
                required
                type="text"
                id="providerName"
                name="providerName"
              />
            </div>
            <div className="column">
              <label htmlFor="providerNPI">Provider NPI #:</label>
              <input required type="text" id="providerNPI" name="providerNPI" />
            </div>
          </div>

          <div className="row">
            <div className="column">
              <label htmlFor="taxID">Tax ID #:</label>
              <input type="text" id="taxID" name="taxID" />
            </div>
            <div className="column">
              <label htmlFor="officeContact">Office Contact Name:</label>
              <input type="text" id="officeContact" name="officeContact" />
            </div>
          </div>
          <div className="row">
            <div className="column">
              <label htmlFor="phone">Phone:</label>
              <input type="tel" id="phone" name="phone" />
            </div>
            <div className="column">
              <label htmlFor="fax">Fax:</label>
              <input type="text" id="fax" name="fax" />
            </div>
          </div>
        </fieldset>

        <fieldset>
          <legend>Place of Service</legend>
          <div className="row">
            <div className="column">
              <label htmlFor="facilityName">Facility Name:</label>
              <input type="text" id="facilityName" name="facilityName" />
            </div>
            <div className="column">
              <label htmlFor="facilityNPI">Facility NPI #:</label>
              <input type="text" id="facilityNPI" name="facilityNPI" />
            </div>
          </div>

          <div className="row">
            <div className="column">
              <label htmlFor="facilityTaxID">Tax ID #:</label>
              <input type="text" id="facilityTaxID" name="facilityTaxID" />
            </div>
            <div className="column">
              <label htmlFor="facilityAddress">Address:</label>
              <input type="text" id="facilityAddress" name="facilityAddress" />
            </div>
          </div>

          <div className="row">
            <div className="column">
              <label htmlFor="facilityCity">City:</label>
              <input type="text" id="facilityCity" name="facilityCity" />
            </div>
            <div className="column">
              <label htmlFor="facilityState">State:</label>
              <input type="text" id="facilityState" name="facilityState" />
            </div>
            <div className="column">
              <label htmlFor="facilityZip">ZIP Code:</label>
              <input type="text" id="facilityZip" name="facilityZip" />
            </div>
          </div>

          <div>
            <label>Service Type:</label>
            <div className="row">
              <div className="column">
                <label>
                  <input type="checkbox" name="serviceType" value="OUTPT" />{" "}
                  OUTPT
                </label>
              </div>
              <div className="column">
                <label>
                  <input type="checkbox" name="serviceType" value="OFFICE" />{" "}
                  OFFICE
                </label>
              </div>
              <div className="column">
                <label>
                  <input
                    type="checkbox"
                    name="serviceType"
                    value="SURGERY CTR"
                  />{" "}
                  SURGERY CTR
                </label>
              </div>
            </div>
            <div className="row">
              <div className="column">
                <input type="checkbox" name="serviceType" value="OTHER" />
                <label
                  htmlFor="otherService"
                  id="otherService"
                  style={{
                    marginLeft: 10,
                    marginRight: 10,
                    display: "inline-block",
                  }}
                >
                  Other:
                </label>
                <input
                  type="text"
                  name="otherService"
                  style={{ width: "calc(100% - 79px)" }}
                />
              </div>
            </div>
          </div>
        </fieldset>

        <fieldset>
          <legend>Services</legend>
          <div className="row">
            <div className="column">
              <label htmlFor="dos">Date of Service</label>
              <input type="date" id="dos" name="dos" />
            </div>
          </div>
          <div className="row">
            <div className="column">
              <label>Product (Check One and Add Units):</label>
              <div className="row">
                <div className="column">
                  <label>
                    <input type="radio" name="product" value="Q4205" /> Membrane
                    Wrap (Q4205):
                  </label>
                </div>
                <div className="column">
                  <input type="text" name="q4205" placeholder="units/cm2" />
                </div>
              </div>
              <div className="row">
                <div className="column">
                  <label>
                    <input type="radio" name="product" value="Q4290" /> Membrane
                    Wrap - Hydro:
                  </label>
                </div>
                <div className="column">
                  <input type="text" name="q4290" placeholder="units/cm2" />
                </div>
              </div>
            </div>
          </div>

          <label htmlFor="icdCodes">ICD-10 Diagnosis Codes:</label>
          <textarea id="icdCodes" name="icdCodes"></textarea>

          <label htmlFor="cptCodes">CPT Application Codes:</label>
          <textarea id="cptCodes" name="cptCodes"></textarea>
        </fieldset>

        <fieldset>
          <legend>Additional Email Addresses to send results to</legend>
          <div className="row">
            <div className="column">
              <label htmlFor="email1">Email:</label>
              <input type="text" id="email1" name="email[]" />
            </div>
            <div className="column">
              <label htmlFor="email2">Email:</label>
              <input type="text" id="email2" name="email[]" />
            </div>
          </div>
          <div className="row">
            <div className="column">
              <label htmlFor="email3">Email:</label>
              <input type="text" id="email3" name="email[]" />
            </div>
            <div className="column">
              <label htmlFor="email4">Email:</label>
              <input type="text" id="email4" name="email[]" />
            </div>
          </div>
        </fieldset>

        <Signature />
      </form>

      <hr />

      <div className="row">
        <div className="column">
          ALL SECTIONS OF THIS FORM MUST BE COMPLETED. ANY MISSING INFORMATION
          COULD DELAY THE PROCESSING TIME OF THE REQUEST. IF YOU NEED ASSISTANCE
          FILLING OUT THIS FORM, PLEASE CONTACT THE CLIENT SERVICES TEAM. This
          authorization is not a guarantee of payment. Payment is contingent
          upon eligibility, benefits available at the time the service is
          rendered, contractual terms, limitations, exclusions, and coordination
          of benefits, and other terms & conditions set forth in the member’s
          evidence of coverage. The information contained in this form,
          including attachments, is privileged and confidential & is only for
          the use of the individual or entities named on this form. If the
          reader of this form is not the intended recipient or the employee or
          the agent responsible to deliver to the intended recipient, the reader
          is hereby notified that any dissemination, distribution, or copying of
          this communication is strictly prohibited. If this communication has
          been received in error, the reader shall notify the sender immediately
          and shall destroy all information received.
        </div>
      </div>
    </div>
  );
};

export default InsuranceVerificationForm;
