import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import { useUser } from "@providers/user";
import { useApi } from "@providers/api";
import "./style.css";

const activeStyle = { color: "#BC4A1A", borderBottom: "1px solid #BC4A1A" };
const logoStyle = { float: "left", marginRight: "35px" };

export default function Nav() {
  const { user } = useUser();
  const api = useApi();
  const { pathname } = useLocation();
  const handleLogout = () => {
    const confirmed = window.confirm("Are you sure you want to logout?");
    if (confirmed) {
      window.localStorage.clear();
      window.location.reload();
    }
  };
  const styleForPath = (path) => (path.test(pathname) ? activeStyle : {});
  const [showMenu, setShowMenu] = useState(false);
  const [environment, setEnvironment] = useState(null);

  useEffect(() => {
    api.get("/environment").then((response) => {
      setEnvironment(response.environment);
    });
  }, []);

  if (user?.roles?.includes("super")) {
    return (
      <nav className={`navbar no-print ${environment || ""}`}>
        <div className="container">
          <div className="navigation">
            <img
              style={logoStyle}
              src="/EWCC-Monogram.png"
              alt="EWCC Logo"
              width="100px"
              height="22.77px"
            />
            <div className="hamburger" onClick={() => setShowMenu(!showMenu)}>
              {showMenu ? (
                <img src="/close.svg" alt="menu" width="30px" height="30px" />
              ) : (
                <img src="/menu.svg" alt="menu" width="30px" height="30px" />
              )}
            </div>
            <div
              className={["links", showMenu ? "active" : "inactive"].join(" ")}
              onClick={() => setShowMenu(false)}
            >
              <Link style={styleForPath(/^\/$/)} to="/">
                Home
              </Link>
              <Link style={styleForPath(/^\/order$/)} to="/order">
                Place Order
              </Link>
              <Link style={styleForPath(/^\/admin\/orders/)} to="/admin/orders">
                Orders
              </Link>
              <Link style={styleForPath(/^\/admin\/users/)} to="/admin/users">
                Users
              </Link>
              <Link
                style={styleForPath(/^\/admin\/offices/)}
                to="/admin/offices"
              >
                Offices
              </Link>
              <Link
                style={styleForPath(/^\/admin\/products/)}
                to="/admin/products"
              >
                Products
              </Link>
              <Link
                style={styleForPath(/^\/admin\/vendors/)}
                to="/admin/vendors"
              >
                Vendors
              </Link>
              <Link
                style={styleForPath(/^\/admin\/accounts/)}
                to="/admin/accounts"
              >
                Accounts
              </Link>
              <Link
                style={styleForPath(/admin\/invoices/)}
                to="/admin/invoices"
              >
                Invoicing
              </Link>
              <a className="float-right" onClick={handleLogout}>
                Logout
              </a>
            </div>
          </div>
        </div>
      </nav>
    );
  } else if (
    user?.roles?.includes("accounting") ||
    user?.roles?.includes("sales_rep")
  ) {
    return (
      <nav className="navbar no-print">
        <div className="container">
          <div className="navigation">
            <img
              style={logoStyle}
              src="/EWCC-Monogram.png"
              alt="EWCC Logo"
              width="100px"
              height="22.77px"
            />
            <Link style={styleForPath(/^\/$/)} to="/">
              Home
            </Link>
            <Link style={styleForPath(/^\/order$/)} to="/order">
              Place Order
            </Link>
            <Link style={styleForPath(/^\/orders/)} to="/orders">
              Orders
            </Link>
            <Link style={styleForPath(/^\/invoices/)} to="/invoices">
              Invoices
            </Link>
            <a className="float-right" onClick={handleLogout}>
              Logout
            </a>
          </div>
        </div>
      </nav>
    );
  } else {
    return (
      <nav className="navbar no-print">
        <div className="container">
          <div className="navigation">
            <img
              style={logoStyle}
              src="/EWCC-Monogram.png"
              alt="EWCC Logo"
              width="100px"
              height="22.77px"
            />
            <Link style={styleForPath(/^\/$/)} to="/">
              Home
            </Link>
            <Link style={styleForPath(/^\/order$/)} to="/order">
              Place Order
            </Link>
            <Link style={styleForPath(/^\/orders/)} to="/orders">
              Orders
            </Link>
            <a className="float-right" onClick={handleLogout}>
              Logout
            </a>
          </div>
        </div>
      </nav>
    );
  }
}
