import React from "react";
import MultiSelect from "@shared/MultiSelect";

export default function SelectAccount({
  accounts,
  selectedAccounts,
  setSelectedAccounts,
  superUser,
  salesRepUser,
}) {
  if (superUser) {
    return null;
  }

  if (salesRepUser) {
    return (
      <div className="column">
        <label htmlFor="account_ids">Accounts</label>
        <MultiSelect
          options={accounts.map((account) => ({
            label: account.name,
            value: account.id,
          }))}
          selectedValues={selectedAccounts}
          onChange={(selectedValues) => {
            setSelectedAccounts(selectedValues);
          }}
          name="account_ids"
        />
      </div>
    );
  }

  return (
    <div className="column">
      <label htmlFor="account_ids">Account</label>
      <select
        value={selectedAccounts[0]}
        name="account_ids"
        onChange={(e) => setSelectedAccounts([e.target.value])}
      >
        <option value="">Select Account</option>
        {accounts.map((account) => (
          <option key={account.id} value={account.id}>
            {account.name}
          </option>
        ))}
      </select>
    </div>
  );
}
